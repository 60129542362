/**
NU-Frist in Abhängigkeit der Untersuchungsart (EU/NU)
Nur für Vorsorgen relevant (gilt für Angebots-&Pflichtvorsorgen)
EU = Nachuntersuchungsfrist 12 Monate
NU = Nachuntersuchungsfrist 36 Monate
Vorsorgen sind alle Untersuchungen außer: G25, G41, G28.1, G28.2, G26.2 & G26.3
-> Achtung G26.2 & G26.3 können sowohl Vorsorge als auch Eignung sein!
 */
export default {
    'Angebot':{
        "EU": 12,
        "NU": 36
    },
    'Pflicht': {
        "EU": 12,
        "NU": 36
    },
}