import React, { Component } from 'react';
import { menuConfig, adminMenuConfig, menuErweitertConfig } from '../config/MenuConfig';

class Sidebar extends Component {
  state = { 
    links: [ ],
    linkParents: [],
    adminLinks: []
  }

  validateData(props) {
    this.setState({ 
      links: Object.keys(menuConfig).map( idx => {
        let label = (menuConfig[idx].label) ? menuConfig[idx].label : idx;
        let icon = (menuConfig[idx].icon) ? menuConfig[idx].icon : "";
        return { link: menuConfig[idx].link, label: label, icon: icon }
      })
    })

    //array noch filter auf not null :)
    this.setState({ 
      adminLinks: Object.keys(props.adminLinks).map( (idx) => {
        if( props.adminLinks[idx].label in adminMenuConfig){
          let label = adminMenuConfig[props.adminLinks[idx].label].label;
          let link = adminMenuConfig[props.adminLinks[idx].label].link;
          let icon = adminMenuConfig[props.adminLinks[idx].label].icon
          return { link: link, label: label, icon: icon }
        }
        return null;
      }).filter( ele => {return ele != null; }) 
    }); 

  }
  componentDidMount() {
    this.validateData(this.props);
  }
  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validateData(newProps)
    }
  }

  createLink(linkObj, idx = 0) {
    let {link, label, icon} = linkObj
    let active = window.location.hash === link ? "active" : "";
    let li =
      <li className={`nav-item ${active}`} key={idx}>
        <a href={link} className="nav-link">
          <span>
            <i className={`sii ${icon}`}></i>
            <p>{global.capitalize(label.replace('#', ''))}</p>
          </span>
        </a>
      </li>
    ;

    return li
  }

  render() {
    let adminUl = <>
      <span className="nav-title txt-caps-small-regular mt-5">ADMIN</span>
      <ul id="admin-nav" className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        { this.state.adminLinks.map( (link, idx) => this.createLink(link, idx) ) }
      </ul>
    </>
    ;

    return (

      <aside className="main-sidebar sidebar-no-expand">
        <a href="#dashboard" className="brand-link">
          <img src="dist/img/DIVOKA_Logo.png" alt="DiVoKa Logo" className="brand-image" />
          {/* <span className="brand-text font-weight-light">Vorsorgekartei</span> */}
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <span className="nav-title txt-caps-small-regular">MENÜ</span>
            <ul id="allg-nav" className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              { this.state.links.map( (link, idx) => this.createLink(link, idx)  )}
            </ul>
            
            { global.isAdmin ? adminUl: "" }

            <ul id="erweitert-nav" className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              { Object.entries(menuErweitertConfig).map(([key, value]) => {
                  return this.createLink(value, key) 
                }) 
              }
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
