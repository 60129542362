/**
 * Config welche Spalten in einer Tabelle angezeigt werden, mit welchem Namen
 */

import { MITARBEITER_DETAILS, MITARBEITER_UEBERSICHT } from "../utils/TableConstanten";

//Tabellen Einstellung
export var tableColums = {
  mitarbeiter: [ //spalten für die Mitarbeiter Übersicht
    { dbName: "nachname", label: "Nachname" },
    { dbName: "vorname", label: "Vorname" },
    { dbName: "geburtstag", label: "Geburtstag", format: 'DD.MM.YYYY' },
    { dbName: "email", label: "E-Mail" },
    { dbName: "zuordnungs.bezeichnung", label: "Tätigkeit/Bereich" },
    { dbName: "zuordnungs.kommentar", label: "Kommentar" },
  ],
  firmenkartei: [ 
    { dbName: "bg_grundsatz", label: "BG Grundsatz" },
    { dbName: "anlass", label: "Anlass" },
    { dbName: "titel", label: "Titel" },
    { dbName: "vorsorgekartei.vorsorgeart", label: "Vorsorgeart" },
    { dbName: "vorsorgekartei.beschreibung", label: "Beschreibung" },
  ],
  zuordnung: [ 
    { dbName: "bezeichnung", label: "Tätigkeitsbereich" },
    { dbName: "typ", label: "Typ" },
    { dbName: "kommentar", label: "Kommentar" },
    { dbName: "firmen.name", label: "Firma" },
  ],
  vorsorgekartei: [ 
    { dbName: "bg_grundsatz", label: "BG Grundsatz" },
    { dbName: "anlass", label: "Anlass" },
    { dbName: "titel", label: "Titel" },
    { dbName: "vorsorgeart", label: "Vorsorgeart" },
    { dbName: "beschreibung", label: "Beschreibung" },
    { dbName: "ablaufsfrist", label: "Ablaufsfrist" },
  ],
  firmen: [ 
    { dbName: "name", label: "Name" },
    { dbName: "strasse", label: "Strasse" },
    { dbName: "plz", label: "PLZ" },
    { dbName: "ort", label: "Ort" },
  ],
  untersuchungen: [ 
    { dbName: "art", label: "Art" },
    { dbName: "gemacht_am", label: "durchgeführt", format: 'MM/YYYY' },
    { dbName: "naechster_termin", label: "Nachuntersuchung", format: 'MM/YYYY' },
    { dbName: "beurteilung", label: "Beurteilung" },
    { dbName: "kommentar", label: "Kommentar" },
    { dbName: "status", label: "Status" },
    { dbName: "mitarbeiter.nachname", label: "Nachname" },
    { dbName: "mitarbeiter.vorname", label: "Vorname" },
  ],
  benutzer: [ 
    // { dbName: "username", label: "E-Mail" }, //INFO Username wird nicht benutzt
    { dbName: "email", label: "E-Mail" },
    { dbName: "rolle", label: "Rolle" },
    { dbName: "firmen.name", label: "Firma" },
  ],
  [MITARBEITER_UEBERSICHT]: [
    { dbName: "nachname", label: "Nachname"},
    { dbName: "vorname", label: "Vorname"},
    { dbName: "bezeichnung", label: "Bezeichnung"},
    { dbName: "statistik", label: "Statistik", group: [
      "red", "yellow", "green"
    ]},
  ],
  [MITARBEITER_DETAILS]: [
    { dbName: "bg_grundsatz", label: "BG Grundsatz"},
    { dbName: "beurteilung", label: "Beurteilung"},
    { dbName: "gemacht_am", label: "Durchgeführt", format: 'MM-YYYY'},
    { dbName: "naechster_termin", label: "Nachuntersuchung", format: 'MM-YYYY'}
  ]

};

//felder die generell ausgeblendet werden sollen
//hier wird in der Table.js beim heading und cell geprüft ob hier enthalten, wenn ja wird d-none als class geadded
export var hideByAll = [
    'id',   
    'pwd',
]

// Umbenennungen generell
export var renameColName = {
    bg_grundsatz: {label: 'BG Grundsatz'},
    gemacht_am: {label: 'durchgeführt'},
    naechster_termin: {label: 'Nachuntersuchung'},
    bezeichnung: {label: 'Tätigkeitsbereich'},
    firmen_name: {label: "Firma"}
}


export var formatDate = {
    gemacht_am: 'MM/YYYY',
    naechster_termin: 'MM/YYYY',
    geburtstag: 'DD.MM.YYYY',
}

//Feste Spalten in der Report(Vorsorgekartei) Tabelle 
export var reportStandardCols = [
    // "mitarbeiter.id",
    "mitarbeiter.nachname",
    "mitarbeiter.vorname",
    // "firmenkartei.id", 
    "firmenkartei.titel", 
    "firmenkartei.bg_grundsatz",
    // "untersuchungen.id", 
    "untersuchungen.beurteilung", 
    "untersuchungen.gemacht_am", 
    "untersuchungen.naechster_termin", 
    "untersuchungen.kommentar",
    "zuordnung.bezeichnung"
]

export var reportStandardColsMitarbeiter = [
    // "mitarbeiter.id",
    // "mitarbeiter.nachname",
    // "mitarbeiter.vorname",
    // "firmenkartei.id", 
    "firmenkartei.titel", 
    "firmenkartei.bg_grundsatz",
    // "untersuchungen.id", 
    "untersuchungen.beurteilung", 
    "untersuchungen.gemacht_am", 
    "untersuchungen.naechster_termin", 
    "untersuchungen.kommentar",
    "zuordnung.bezeichnung"
]

//Feste Spalten fpr report Mitarbeiter_uebersicht
export var reportMitarbeiterUebersicht = [
    "nachname",
    "vorname",
    "bezeichnung",
    "statistik"
]

// alter wert   : neuer wert
export var toolBoxTabRename = {
    zuordnung: 'Tätigkeit/Bereich', 
    firmenkartei: "Vorsorgen/Eignungen",
}
export var toolBoxColRename = {
    bg_grundsatz: "BG Grundsatz",
    gemacht_am: "durchgeführt",
    naechster_termin: "Nachuntersuchung"
}


//Formular/Modal renames
export var modalTitleRename = {
    vorsorgekartei: "Untersuchung",
}

//Rename der Form Labels
export var formInputRename = {
    bg_grundsatz: "BG Grundsatz",
}

//Tabellen Standart Sortier angaben
export var tableDefaultSort = {
    'mitarbeiter': { 
        'sortOrder': 'ASC',
        'orderBy': 'nachname'
    },
}