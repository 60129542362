import { FIRMENKARTEI, REPORT, VORSORGEN, ZUORDNUNG, MITARBEITER_UEBERSICHT, UNTERSUCHUNGEN, MITARBEITER, FIRMEN, BENUTZER } from "../utils/TableConstanten"

//indx = label      link = #link
export var menuConfig = {
    'Dashboard': {link: '#dashboard', icon:"sii-dashboard"},
    'Vorsorgekartei': {link: '#vorsorgenkartei', icon:"sii-vorsorgekartei"},
    'Mitarbeiter': {link: '#mitarbeiter', icon:"sii-mitarbeiter"},
    'Vorsorgen/Eignungen': {link: '#vorsorgen-eignungen', icon:"sii-vorsorgen-eignungen"},
    'Tätigkeiten/Bereiche': {link: '#taetigkeiten-bereiche', icon:"sii-tatigkeiten"}
}

// resourceName    Label
export var adminMenuConfig = {
    'vorsorgekartei': {label: "Untersuchungsliste", link: "#untersuchungsliste", icon:"sii-untersuchungsliste"},
    'firmen': {label: 'Firmen', link: '#firmen', icon:"sii-firmen"},
    'untersuchungen': {label: 'Untersuchungen', link: '#untersuchungen', icon:"sii-untersuchungen"},
    'benutzer': {label: 'Benutzer', link: '#benutzer', icon:"sii-benutzer"},
}

//erweiterte Links
export var menuErweitertConfig = {
    einstellungen : {label: 'Einstellungen', link: '#einstellungen', icon:"sii-einstellungen"},
    help : {label: 'Benötigen Sie Hilfe?', link: '#hilfe', icon:"sii-hilfe"}
}

//Link namen anpassen aber auf das richtige scheme/resourceName schieben und ggf. schön labeln
//    link name              resourceName                     schönes Titel Label auf single Seite
//                                                              das label zieht auch für die TabelBox
export var hashToSchema = {
    'untersuchungsliste': {schemaResourceName: VORSORGEN, label: "Untersuchungsliste"},
    'vorsorgenkartei_details': {schemaResourceName: REPORT, label: "Report"},
    'vorsorgenkartei': {schemaResourceName: MITARBEITER_UEBERSICHT, label: "Vorsorgekartei"},
    'vorsorgen-eignungen': {schemaResourceName: FIRMENKARTEI, label: 'Vorsorgen/Eignungen'},
    'taetigkeiten-bereiche': {schemaResourceName: ZUORDNUNG, label: 'Tätigkeiten und Bereiche'},
}

export var schemaToForm = {
    'new_mitarbeiter': {schemaResourceName: MITARBEITER, label: 'Neuer Mitarbeiter'},
    'new_vorsorgenkartei': {schemaResourceName: UNTERSUCHUNGEN, label: 'Neues Untersuchungsergebnis anlegen'},
    'new_firmen': {schemaResourceName: FIRMEN, label: 'Neue Firma'},
    'new_vorsorgen-eignungen': {schemaResourceName: FIRMENKARTEI, label: 'Neue Vorsorge/Eignung'},
    'new_taetigkeiten-bereiche': {schemaResourceName: ZUORDNUNG, label: 'Neue Tätigkeit/Bereich'},
    'new_untersuchungsliste': {schemaResourceName: VORSORGEN, label: 'Neue Allgemeine Untersuchung'},
    'new_benutzer': {schemaResourceName: BENUTZER, label: 'Neuer Benutzer'},
}


export var quickActions = {
    "vorsorgenkartei": [
        {qaLink: '#CSV', qaAction: UNTERSUCHUNGEN, qaText: "CSV"},
        {qaLink: 'new_vorsorgenkartei', qaText: "Neues Untersuchungsergebnis"}
    ],
    "mitarbeiter": [
        {qaLink: '#CSV', qaAction: MITARBEITER, qaText: "CSV"},
        {qaLink: 'new_mitarbeiter', qaText: "Neuer Mitarbeiter"}
    ],
    "vorsorgen-eignungen": [{qaLink: 'new_vorsorgen-eignungen', qaText: "Neue Vorsorge/Eignung"}],
    "taetigkeiten-bereiche": [{qaLink: 'new_taetigkeiten-bereiche', qaText: "Neue Tätigkeit/Bereich"}],
    "untersuchungsliste": [
        {qaLink: 'new_untersuchungsliste', qaText: "Neue Allgemeine Untersuchung"}
    ],
    "firmen": [
        {qaLink: 'new_firmen', qaText: "Neue Firma"}
    ],
    "benutzer": [
        {qaLink: 'new_benutzer', qaText: "Neuer Benutzer"}
    ],
}