import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { IoMdClose } from "react-icons/io";
import CustomCheckbox from "../CustomFormFields/CustomCheckbox";
import withLogging from "../../logging/withLogging";
import { BsFilterCircle } from "react-icons/bs";
import { isDate } from "moment";

const selectedFilterNameDefault = "";

class TableFilter extends Component {
  state = {
    filter: {},
    labelActive: false,
    defaultInput: "",
    statistikOptions: ["fällig", "bald fällig", "gültig"],
    isDisabled: true,
  };

  componentDidMount(){
    if(window.location.hash === '#vorsorgenkartei'){
      console.log(localStorage.getItem("detailSort"));
      let statistik = "";
      if(localStorage.getItem("detailSort")){
        switch (localStorage.getItem("detailSort")) {
            case 'red':
                statistik = 'fällig';
                break;
            case 'yellow':
                statistik = "bald fällig"
                break;
            case 'green':
                statistik = "gültig"
                break;
        }
        this.setState({
          selectedFilterName: 'Statistik',
          filterNameLabel: 'Statistik',
          filter: {statistik: statistik}
        }, () => {
          localStorage.removeItem("detailSort")
          this.props.setFilter(this.state.filter)
        })
      }
    }
  }

  changeFilter = (event) => {
    // console.log("Filter auf: ", event.target.name);
    let filterName = event.target.name;
    this.setState(
      {
        selectedFilterName: filterName,
        filterNameLabel: event.target.parentElement.innerText,
        isDisabled: false
      },
      () => {
        document.querySelector(`input[name="filterText"]`).focus();
      }
    );
  };

  handleEnterKeyPressed = (event) => {
    if (event.keyCode === 13) {
      // console.log("KeyPressed", event.target.value);
      event.target.blur();

      const isValid = this.state.statistikOptions.includes(event.target.value);
      if (this.state.filterNameLabel === "Statistik" && !isValid) {
        alert("Bitte wählen Sie eine gültige Option aus der Liste.\n\nOptionen: gültig, bald fällig, fällig");
        return; 
      }

      this.setState(
        {
          filter: {
            ...this.state.filter,
            [this.state.selectedFilterName]: event.target.value,
          },
        },
        () => {
          event.target.value = "";
          this.props.setFilter(this.state.filter);
        }
      );
    }
  };

  deleteFilter(filterKey) {
    // console.log("Delete Filter");
    this.setState(
      (prevState) => {
        const newFilter = { ...prevState.filter };
        // Toggle the filter state
        if (newFilter[filterKey]) {
          delete newFilter[filterKey];
          filterKey = selectedFilterNameDefault;
        }
        return {
          filter: newFilter,
          filterNameLabel: undefined,
          isDisabled: true,
        };
      },
      () => this.props.setFilter(this.state.filter)
    );
  }

  getLabelByDbName(dbName) {
    const { filterFields } = this.props;
    // Finde das Objekt, das dem angegebenen dbName entspricht
    const item = filterFields.find((element) => element.dbName === dbName);
    // Wenn das Objekt gefunden wurde, gib das Label zurück, sonst eine Nachricht
    return item ? item.label : "Label nicht gefunden";
  }

  render() {
    const { filterFields } = this.props;
    const { filterNameLabel, statistikOptions } = this.state;
    const isStatistik = filterNameLabel === "Statistik";
    return (
      <div className="filterToolBox">
        <div className="row no-gutters custom-row">
          <div className="col-auto">
            <InputGroup className="">
              <InputGroupText id="filter"><BsFilterCircle style={{marginRight: '0.571rem'}}/> Filter:</InputGroupText>
              <Dropdown toggle={function noRefCheck() {}}>
                <DropdownToggle
                  data-toggle="dropdown"
                  tag="span"
                  className="form-control"
                >
                  {this.state.filterNameLabel ?? 'Filter wählen'} 
                  <div
                    className="filter dropDownAnkle"
                    style={{ display: "inline", marginLeft: 10 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.64592 4.64601C1.69236 4.59945 1.74754 4.5625 1.80828 4.5373C1.86903 4.51209 1.93415 4.49911 1.99992 4.49911C2.06568 4.49911 2.13081 4.51209 2.19155 4.5373C2.2523 4.5625 2.30747 4.59945 2.35392 4.64601L7.99992 10.293L13.6459 4.64601C13.6924 4.59952 13.7476 4.56264 13.8083 4.53749C13.8691 4.51233 13.9342 4.49938 13.9999 4.49938C14.0657 4.49938 14.1308 4.51233 14.1915 4.53749C14.2522 4.56264 14.3074 4.59952 14.3539 4.64601C14.4004 4.6925 14.4373 4.74769 14.4624 4.80842C14.4876 4.86916 14.5005 4.93426 14.5005 5.00001C14.5005 5.06575 14.4876 5.13085 14.4624 5.19159C14.4373 5.25233 14.4004 5.30752 14.3539 5.35401L8.35392 11.354C8.30747 11.4006 8.2523 11.4375 8.19155 11.4627C8.13081 11.4879 8.06568 11.5009 7.99992 11.5009C7.93415 11.5009 7.86903 11.4879 7.80828 11.4627C7.74754 11.4375 7.69236 11.4006 7.64592 11.354L1.64592 5.35401C1.59935 5.30756 1.56241 5.25239 1.5372 5.19164C1.512 5.1309 1.49902 5.06578 1.49902 5.00001C1.49902 4.93424 1.512 4.86912 1.5372 4.80837C1.56241 4.74763 1.59935 4.69245 1.64592 4.64601Z"
                        fill="#FF6600"
                      />
                    </svg>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {filterFields ? (
                    filterFields.map((column, idx) => (
                      <FormGroup check key={idx}>
                        <CustomCheckbox
                          key={`input-${idx}`}
                          checked={
                            this.state.filter[column.dbName] ? true : false
                          }
                          name={column.dbName}
                          onChange={this.changeFilter}
                          label={column.label}
                        />
                      </FormGroup>
                    ))
                  ) : (
                    <DropdownItem disabled>No items available</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
          </div>
          <div className="col">
            {isStatistik ? (
              <input
                list="statistikOptions"
                name="filterText"
                disabled={this.state.isDisabled}
                onKeyDown={(e) => this.handleEnterKeyPressed(e)}
                placeholder="Wähle eine Option"
                className="form-control"
              />
            ) : (
              <Input
                name="filterText"
                disabled={this.state.isDisabled}
                placeholder={this.state.filterNameLabel}
                defaultValue={this.state.defaultInput}
                onKeyDown={(e) => this.handleEnterKeyPressed(e)}
              />
            )}
            {isStatistik && (
              <datalist id="statistikOptions">
                {statistikOptions.map((option, index) => (
                  <option key={index} value={option} />
                ))}
              </datalist>
            )}
          </div>
        </div>
        <div className="row">
          {Object.keys(this.state.filter).map((key, idx) => {
            return (
              <div className="col-auto">
                <span
                  className="filterBox"
                  key={idx}
                  onClick={(e) => this.deleteFilter(key)}
                >
                  {this.getLabelByDbName(key) +
                    ": " +
                    this.state.filter[key]}
                  <i className="icon">
                    <IoMdClose />
                  </i>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withLogging(TableFilter);

TableFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filterFields: PropTypes.array.isRequired,
};
