import { Component } from "react";
import { toast } from "react-toastify";
 
class WidgetCard extends Component{

    constructor(props){
        super(props);
        this.state = {}

    }

    componentDidMount(){
        global.emitter.on('REFRESH_DASHBOARD', () => {
            this.forceUpdate();
        })
    }

    getCSV(){
        toast.info("CSV Download momentan leider nicht möglich.")
    }

    render() {
        return (<>
            <div className={`widget-card col${ this.props.width === 'full' ? '-12': ''}`}>
                <div className="card">
                    <div className="card-header">
                        <span className="title">{this.props.title}</span>
                        <div className="card-header-actions">
                            <span className="csv" onClick={this.getCSV.bind(this)}>CSV <i className="sii sii-file-down"></i></span>
                            <span className="uebersicht" onClick={() => { 
                                localStorage.removeItem('detailSort')
                                window.location = "#vorsorgenkartei";
                            }}>Zur Übersicht <i className="sii sii-arrow-right"></i></span>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        { this.props.content }
                    </div>
                </div>
            </div>
        </>)
    }
}

// Setze die Default-Werte für die Props
WidgetCard.defaultProps = {
    width: "half", // Default-Wert für die width-Prop
    title: "Default Title", // Default-Wert für die title-Prop
    content: ""
};

export default WidgetCard;