import React from "react";
import IconDropDown from "./IconDropDown";

export default function Profile() {

  let logout = async () => {
    let data = {
      'user': global.auth.user,
      'authToken': global.auth.authToken.token
    }

    let res = await fetch(global.api_url + 'logout', { 
      method: 'DELETE', 
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': global.auth.authToken.token
      },
      body: JSON.stringify(data) 
    });

    if(res.status !== 204){
      //fehler handling -siehe api log
    }else {
      window.localStorage.removeItem('auth');
      window.location.hash = '';
      window.location.reload();
    }
  }
   
  return (
    <>
      <IconDropDown info={{
        wrapperName: "profileSelect",
        main: global.auth.user ? global.auth.user.email : 'Not logged in',
        under: global.auth.user ? global.auth.user.rolle : '',
        icon: "far fa-user",
        dropDown: {
            options: [
                {value: 0, label: "Logout"}
            ],
            onClick: logout,
        }
      }}/>
    </>
  );
}