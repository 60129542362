import React, { Component } from 'react';
// import SelectSearch from 'react-select-search';
import { reportStandardCols, reportStandardColsMitarbeiter, toolBoxColRename, toolBoxTabRename } from '../config/TableViewConfig';
import Select from 'react-select';


export default class ToolBox extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = { possibleFields: [], value: [], init: false,  selectedFields: []}

    }
    converToTitle(fields) {
        let optionsData = fields.map((fieldName) => {
            if(fieldName.split('.')[0] !== "vorsorgekartei" && fieldName.split('.')[1] !== 'id') {
            
                let tmp= {value: fieldName, label: ""};
                let split = fieldName.split('.');

                if(split[0] in toolBoxTabRename){
                    tmp.label = toolBoxTabRename[split[0]]
                }else{
                    tmp.label = global.capitalize(split[0])
                }
                if(split[1] in toolBoxColRename){
                    tmp.label += ' - ' + toolBoxColRename[split[1]];
                }else {
                    tmp.label += ' - ' + global.capitalize(split[1])
                }
                return tmp;
            }
            return null;
        })
        // optionsData.filter(optionsData, optionsData);
        // console.log('ketest', optionsData.filter(e => !!e))
        return optionsData.filter(e => !!e); //null values rausfiltern
    }
    componentDidMount() {
        this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
          this.validate(newProps)
        }
      }

    validate(props){
        let firstSelected = props.mitarbeiterDetails ? reportStandardColsMitarbeiter : reportStandardCols;

        let local = localStorage.getItem('report_fields');
        let selectedFields = (local !== null) ? JSON.parse(local) : firstSelected;
        this.setState({ selectedFields:  this.converToTitle(selectedFields)});
        this.setState({ possibleFields: this.converToTitle(props.fields) });
    }

    selectChangeHandler(selected){
        console.log(selected)
        this.setState({ selectedFields: selected }, () =>{
            let store = selected.map( (item) => item.value);
            localStorage.setItem('report_fields', JSON.stringify(store));
            this.props.updateReport();
        });
    }

    render() {
        return (
            <div style={{ marginRight: 10 }}>
                <Select 
                    placeholder = "Spaltenauswahl"
                    options={ this.state.possibleFields } 
                    value={ this.state.selectedFields }
                    isMulti
                    closeMenuOnSelect={false}
                    className="basic-multi-select"
                    onChange={ this.selectChangeHandler.bind(this) }
                />
            </div>

        );
    }
}




