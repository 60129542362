import React, { Component } from "react";

 class IconDropDown extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
    }
    this.containerRef = React.createRef();
  }

  toggleDropDown(){
    this.setState({showDropdown: !this.state.showDropdown})
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
      this.setState({showDropdown: false})
    }
  };

  render(){
    let options = undefined;
    if(this.props.info.dropDown.options){ //Haben wir options?!
      options = this.props.info.dropDown.options.map(data => {
        let selected = "";
        if(typeof this.props.info.dropDown.selected !== 'undefined'){
          selected = Array.isArray(this.props.info.dropDown.selected) ? this.props.info.dropDown.selected[0].value : this.props.info.dropDown.selected.value;
        }
        return <li 
          key={data.value}
          id={data.value}
          className={`nav-item ${selected === data.value ? 'active' : ''}`}
          onClick={() => this.props.info.dropDown.onClick(data.value)}
          >{data.label}</li>;
      })
    }

    let img = "";
    if(this.props.info.imgPath){
      img = <img className="img-circle" src={this.props.info.imgPath} alt="" />

    }else if(this.props.info.icon){
      img = <div className="icon-wrapper"><i className={`${this.props.info.icon}`}></i></div>

    }else{ //FALLBACK
      img = <div className="icon-wrapper"><i className="far fa-user"></i></div>
    }

    return (<>
      {/* <div className="nav-icon-txt" onClick={this.toggleDropDown.bind(this)} onMouseEnter={this.toggleDropDown.bind(this)} onMouseLeave={this.toggleDropDown.bind(this)}> //MouseOver nicht gewünscht*/}
      <div className="nav-icon-txt" onClick={this.toggleDropDown.bind(this)} ref={this.containerRef}>
        <div className={this.props.info.wrapperName ? this.props.info.wrapperName : 'default-wrapper'}>
          <div className="img-wrapper">
             { img }
          </div>
          <div className="info">
            {/* <a href="#profile">{ global.auth.user ? global.auth.user.email : 'Not logged in' }</a> */}
            <p className="txt-main">{ this.props.info.main }</p>
            <p className="txt-under">{ this.props.info.under }</p>
          </div>
          <div className="dropDownAnkle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.64592 4.64601C1.69236 4.59945 1.74754 4.5625 1.80828 4.5373C1.86903 4.51209 1.93415 4.49911 1.99992 4.49911C2.06568 4.49911 2.13081 4.51209 2.19155 4.5373C2.2523 4.5625 2.30747 4.59945 2.35392 4.64601L7.99992 10.293L13.6459 4.64601C13.6924 4.59952 13.7476 4.56264 13.8083 4.53749C13.8691 4.51233 13.9342 4.49938 13.9999 4.49938C14.0657 4.49938 14.1308 4.51233 14.1915 4.53749C14.2522 4.56264 14.3074 4.59952 14.3539 4.64601C14.4004 4.6925 14.4373 4.74769 14.4624 4.80842C14.4876 4.86916 14.5005 4.93426 14.5005 5.00001C14.5005 5.06575 14.4876 5.13085 14.4624 5.19159C14.4373 5.25233 14.4004 5.30752 14.3539 5.35401L8.35392 11.354C8.30747 11.4006 8.2523 11.4375 8.19155 11.4627C8.13081 11.4879 8.06568 11.5009 7.99992 11.5009C7.93415 11.5009 7.86903 11.4879 7.80828 11.4627C7.74754 11.4375 7.69236 11.4006 7.64592 11.354L1.64592 5.35401C1.59935 5.30756 1.56241 5.25239 1.5372 5.19164C1.512 5.1309 1.49902 5.06578 1.49902 5.00001C1.49902 4.93424 1.512 4.86912 1.5372 4.80837C1.56241 4.74763 1.59935 4.69245 1.64592 4.64601Z" fill="#FF6600"/>
            </svg>
          </div>
        </div>
        { this.state.showDropdown && options !== undefined ? (
          <div className="dropdownArea">
            <ul className="navbar-nav txt-label-small-regular">
              { options }
            </ul>
          </div>) : ''
        }
      </div>
    </>);
  }
}

export default IconDropDown;