import React, { Component } from 'react';
import Profile from '../components/Header/ProfileSelektor';
import FirmenSelektor from '../components/Header/FirmenSelektor';

class Header extends Component {
  state = { schema: {}, data: {} }

  validateData() {

  }
  componentDidMount() {
    this.validateData(this.props);
  }
    componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validateData(newProps)
    }
  }

  render() {

    return (<>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <div className="link-wrapper">
              <a className="nav-link" data-widget="pushmenu" href="/" role="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12 7.99999C12 8.1326 11.9474 8.25978 11.8536 8.35354C11.7598 8.44731 11.6326 8.49999 11.5 8.49999H5.70704L7.85404 10.646C7.90053 10.6925 7.9374 10.7477 7.96256 10.8084C7.98772 10.8691 8.00067 10.9342 8.00067 11C8.00067 11.0657 7.98772 11.1308 7.96256 11.1916C7.9374 11.2523 7.90053 11.3075 7.85404 11.354C7.80755 11.4005 7.75236 11.4374 7.69162 11.4625C7.63088 11.4877 7.56578 11.5006 7.50004 11.5006C7.4343 11.5006 7.3692 11.4877 7.30846 11.4625C7.24772 11.4374 7.19253 11.4005 7.14604 11.354L4.14604 8.35399C4.09948 8.30755 4.06253 8.25237 4.03733 8.19162C4.01212 8.13088 3.99915 8.06576 3.99915 7.99999C3.99915 7.93422 4.01212 7.8691 4.03733 7.80836C4.06253 7.74761 4.09948 7.69244 4.14604 7.64599L7.14604 4.64599C7.23993 4.5521 7.36726 4.49936 7.50004 4.49936C7.63282 4.49936 7.76015 4.5521 7.85404 4.64599C7.94793 4.73988 8.00067 4.86722 8.00067 4.99999C8.00067 5.13277 7.94793 5.2601 7.85404 5.35399L5.70704 7.49999H11.5C11.6326 7.49999 11.7598 7.55267 11.8536 7.64644C11.9474 7.74021 12 7.86738 12 7.99999Z" fill="#676361"/>
                </svg>
              </a>
            </div>
          </li>
          <li className='nav-item'>
            <FirmenSelektor />
          </li>
          <li className='nav-item nav-item-right'>
            <Profile />
          </li>
        </ul>
      </nav>
    </>
    );
  }
}

export default Header;
