import React, { Component } from "react";
import PropTypes from 'prop-types';
import DonutChart from "./PieChart_Statistik/DonutChart";
import JahresStatistikBarChart from "./PieChart_Statistik/JahresStatistikBarChart";

const color = {
    red: '#DC2626',
    yellow: '#FBBF24',
    green: '#a3e635'
}

class PieChartOverview extends Component{

    state = {
        donut: this.props.donut,
        yearly: this.props.yearly,
    }

    render() {

        return (<>
            <div className="row">
                { this.state.donut ? <DonutChart half={this.state.yearly} color= {color} /> : "" }
                { this.state.yearly ? <JahresStatistikBarChart half={this.state.donut} color={color} /> : "" }
            </div>
        </>
        )
    }
}

export default PieChartOverview;

PieChartOverview.propTypes = {
    donut: PropTypes.bool,
    yearly: PropTypes.bool
}