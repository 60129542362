import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
class ConfirmationDialog extends React.Component {
  
  render() {
    const { isOpen, title, message, onConfirm, onClose} = this.props

    if (!isOpen) {
      return null;
    }

    return (<>
        <Modal 
          className='confirm'
          isOpen={isOpen}
          backdrop={true}
          keyboard={true}
          centered={true}
        >
          <ModalHeader toggle={onClose}>{title}</ModalHeader>
          <ModalBody>
            { <p dangerouslySetInnerHTML={{ __html: message }} /> }
            <small>
              Achtung: Gelöschte Daten können nicht wiederhergestellt werden, sie werden endgültig vom Server gelöscht.
              Sichern Sie bitte alle relevanten Informationen vor dem Löschen.
            </small>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" className='btn-outline' onClick={onClose}>Abbrechen</Button>
            <Button color="danger" onClick={onConfirm}>{title}</Button>{' '}
          </ModalFooter>
      </Modal>
    </>
    );
  }
}

export default ConfirmationDialog;