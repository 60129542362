import React, { Component } from "react";

class WidgetSettings extends Component {

    render() {
            return (<>
                <div className="col widget-config">
                    <div className="card">
                        <div className="card-header">
                            <h5>Widget: "Untersuchungen Übersicht"</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-group row">
                                <label className="col-3" htmlFor="self">Widget Anzeigen:</label>
                                <label className="switch">
                                    <input data-group="UntersuchungenOverview" name="self" type="checkbox" onChange={this.props.checkBoxChange} checked={this.props.value.self}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-3"  htmlFor="donut">Donut Chart:</label>
                                <label className="switch">
                                    <input data-group="UntersuchungenOverview"  name="donut" type="checkbox" onChange={this.props.checkBoxChange}  checked={this.props.value.donut}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-3"  htmlFor="yearly">Jahres Übersicht:</label>
                                <label className="switch">
                                    <input data-group="UntersuchungenOverview"  name="yearly" type="checkbox" onChange={this.props.checkBoxChange}  checked={this.props.value.yearly}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default WidgetSettings;