import React, { Component } from 'react';
import withLogging from '../logging/withLogging';

class Content extends Component {
  static isOwnComponent = true;

  state = { schema: {}, data: {} }

  validateData() {

  }
  componentDidMount() {
    this.validateData(this.props);
  }

  render() {

    return (
      <section className="content">
        <div className="container-fluid">
            {this.props.children}
        </div>
      </section>
    );
  }
}

export default withLogging(Content);
