import { fetchApi } from "../../../utils/DatabaseHelper"

export let newEmploye = {
        label: 'neuer Mitarbeiter angelegt',
        desc: 'wird gesendet wenn ein neuer Mitarbeiter angelegt wurde.',
        form_schema: { //INFO definition muss wegen dem Wechel vorhanden sein, wird aber sosnt nicht beachtet :D
            "definitions": {
                "taetigkeit":{
                    "title": "für welchen Tätigkeitsbereich",
                    "type": 'array',
                    "uniqueItems": true,
                    "description": "Wenn kein Tätigkeitsbereich ausgewählt wird, werden alle Tätigkeiten/Bereiche geprüft.",
                    "items": {
                        "anyOf": [
                            { "const": 1, "title": 'My Foo' },
                            { "const": 2, "title": 'My Foo' }
                        ],
                    }
                },
                untersuchungen: {
                    title: "für welche Untersuchung",
                    type: "array",
                    uniqueItems: true,
                    description: "Nur ausgewählte Untersuchugen werden geprüft (für alle, bitte Alle markiern)",
                    items: {
                      anyOf: [
                        { const: 1, title: " " }
                      ],
                    }
                  },
            },
            "type": "object",
            "required": [
                "from", "to", "when"
            ],
            "properties": {
                "from": {
                    "type": "string",
                    "title": "Sender E-Mail",
                    "default": "info@divoka.de"
                },
                "to": {
                    "type": "object",
                    "title": "Empfänger",
                    "oneOf": [
                        {
                            "title": "Mitarbeiter",
                            "properties": {
                                "mitarbeiter": {
                                    "type": "string",
                                    "title": " E-Mail",
                                    "readOnly": true,
                                }
                            },
                            "required": [ "mitarbeiter" ]
                        },
                        {
                            "title": "Individuell",
                            "properties": {
                                "email": {
                                    "type": "string",
                                    "title": "E-Mail Adresse"
                                }
                            },
                            "required": [ "email" ]
                        }
                    ]
                },
                "when":{
                    "title": "Wann soll die Mail gesendet werden",
                    "type": "object",
                    "oneOf": [
                        {
                            "title": "direkt",
                            "properties": {
                              "sofort": {
                                "type": "string",
                                "readOnly": true,
                              }
                            },
                            "required": ["sofort"]
                          },
                        {
                            "title": "zu einem späterem Zeitpunkt",
                            "properties": {
                                "zahl":{
                                    "title": "x später",
                                    "type": "number",
                                },
                                "auswahl": {
                                    "type": "string",
                                    "enum": [
                                        "Tage",
                                        "Monate",
                                    ]
                                }
                            },
                            "required": [ "zahl", "auswahl" ]
                        }
                    ]
                },
                "subject": {
                    "type": "string",
                    "title": "Betreff"
                },
                "text": {
                    "type": "string",
                    "title": "E-Mail Text"
                },
            },
        },
        uiSchema: {
            "text": {
                "ui:widget": "textarea",
                "ui:options": {
                    "rows": 5
                }
            },
            "readonly": {
                "ui:readonly": true
            },
            "to":{
                "ui:classNames": "si-oneOf" ,
                "mitarbeiter":{
                    "ui:placeholder": "E-Mail geht an den betreffenden Mitarbeiter"
                }
            },
            "when":{
                "ui:classNames": "si-oneOf",
                "sofort":{
                    "ui:placeholder": "Wird nach dem anlegen versendet"
                }
            },

        },
        function: trigger,   
        mail_context: [
            "mitarbeiterId",
            "mitarbeiterfirmenId",
            "mitarbeitername",
            "mitarbeitergeburtstag",
            "mitarbeiteremail",
            "untersuchungenbg_grundsatz",
            "untersuchungenanlass",
            "untersuchungentitel",
            "untersuchungenId",
            "untersuchungenart",
            "untersuchungenablaufdatum",
        ],
        context_functions: [
            '<list></list>'
        ]
}

export async function trigger(data){
    console.log('newEmploye Trigger!!!!', data)
    let query = "?where=where m.Id="+data.id;

    await fetchApi('mails', 'newEmploye', query).then(res => {
        console.log(res)
    }).catch(err => console.log(err))

}