import React, { Component } from "react";
import PropTypes from "prop-types";

class LoadingSpinner extends Component {

    state= {
        show: false
    }

    componentDidMount(){
        this.setState({show: this.props.show})
    }

    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({show: newProps.show})
        }
    }

    render(){
        return this.state.show ? 
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
        :
        <div />
    }
}
LoadingSpinner.propTypes = {
    show: PropTypes.bool.isRequired,
}

export default LoadingSpinner;