import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import './scss/style.scss';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
      < App />
  </React.StrictMode>,
  document.getElementById('root')
);  


//INFO: für React 18 müsste man die ganzen setStates anpassen!!!
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//   <StrictMode>
//     <App />
//   </StrictMode>
// );