import React from 'react';
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
class ArchiveDialog extends React.Component {
  
  render() {
    const { isOpen, title, message, buttons, onClose} = this.props

    if (!isOpen) {
      return null;
    }

    return (<>
        <Modal
          isOpen={isOpen}
          backdrop={true}
          keyboard={true}
          centered={true}
        >
          <ModalHeader toggle={onClose}>{title}</ModalHeader>
          <ModalBody>{ <p dangerouslySetInnerHTML={{ __html: message }} /> }</ModalBody>
          <ModalFooter>
          {buttons.map((button, idx) => (
            <Button key={idx} color={button.color} onClick={button.onClick}>
              {button.label}
            </Button>
          ))}
          </ModalFooter>
      </Modal>
    </>
    );
  }
}
export default ArchiveDialog;

ArchiveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired

}