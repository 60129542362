import React, { Component } from "react";
import Select from 'react-select'
import * as tabConst from '../../../utils/TableConstanten'

class BenutzerForm extends Component {
 
    constructor(props){
        super(props);
        this.default = {
            editForm: false,
            resourceId: null, 
            firmenSelectOptions: [{ value: 'ID', label: "Name" }],
            rolleSelectOptions: [{ value: 'rolle', label: "Rolle wählen" }],
            selectedFirmenOption: [],
            selectedRolleOption: [],
            data:{
                email: "",
                pwd: "",
                rolle: "",
                firmenId: global.filterFirmenId,
            },
        };
        this.functions = props.functions

        this.onInputchange = this.onInputchange.bind(this);
        this.handleFirmenSelectChange = this.handleFirmenSelectChange.bind(this);
        this.handleRolleSelectChange = this.handleRolleSelectChange.bind(this);
        this.state = {...this.default};
    }

    async validate(props) {
        if(props.resourceId){ //Edit Form
            // console.log("Edit Form!")
            this.setState({
                editForm: true,
                resourceId: props.resourceId
            })
            let user = await this.functions.fetchData(props.resourceName+"/"+props.resourceId);

            this.setState({data: { ...this.state.data, 
                email: user.email,
                rolle: user.rolle,
                pwd: user.pwd,
                firmenId: user.firmenId,
            }});
        }

        //firmenSelectOptions         
        this.setState({ firmenSelectOptions: await this.functions.setFirmenSelectOptions()}, () => {
            //wenn der Init = 0 ist haben wir kein defaultValue
            if(this.state.data.firmenId === 0){
                this.setState({data: { ...this.state.data, 
                    firmenId: this.state.firmenSelectOptions[0].value 
                }});
            }
            this.state.firmenSelectOptions.map(opt => {
                // console.log('test', this.state.data.firmenId, opt.value)
                if(opt.value === this.state.data.firmenId){
                    this.setState({ selectedFirmenOption: {value: opt.value, label: opt.label} })
                }
            })
        });

        //rolle auswahl setzen
        let options = props.schema.properties.rolle.enum.map((rolle) => {
            return { value: rolle, label: rolle }
        })
        this.setState({ rolleSelectOptions: options }, () => {
            //default setzen
            if(this.state.data.rolle === ""){
                this.setState({data: { ...this.state.data, rolle: this.state.rolleSelectOptions[0].value }});
                this.setState({ selectedRolleOption: this.state.rolleSelectOptions[0] })
            }else{
                this.state.rolleSelectOptions.map(opt => {
                    if(this.state.data.rolle === opt.value){
                        this.setState({ selectedRolleOption: opt })
                    }
                })
            }
        })

    }

    componentDidMount() {
      this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.validate(newProps)
        }
    }

    /**
     * wenn Firma geändert wird, muss der select der Vorsorgen und MItarbeiter aktualisiert werden. 
     * @param {*} e 
     */
    async handleFirmenSelectChange(e){
        // console.log('test', e)
        console.log('inpChan', 'firmenId', e.value )
        this.setState({selectedFirmenOption: e}, () => this.setState({ data: {...this.state.data, firmenId: e.value }}) )
    }

    async handleRolleSelectChange(e){
        // console.log(e.target)
        this.setState({selectedRolleOption: e}, () => this.setState({ data: {...this.state.data, rolle: e.value }}) )
    }

    onInputchange(event){
        this.setState({data: {...this.state.data, [event.target.name]: event.target.value  }});
        // console.log('inpChan', event.target.name, event.target.value )
    }

    put_post_fetch(method, url){
        // console.log('save this:' ,this.state.data)
        let req = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': global.auth.authToken.token 
            },
            body: JSON.stringify(this.state.data)
        };
        return fetch(url, req)
          .then(res => res.json())
          .then(data => {
              console.log('User '+ data.id +' - ' + data.email + ' angelegt')
          });
    }

    saveOrUpdate(){
        //Update oder Save?
        if(this.state.editForm){ //wird wurden als Edit aufgerufen
            return this.put_post_fetch("PUT", global.api_url + tabConst.BENUTZER+'/'+this.state.resourceId);
        }else{
            return this.put_post_fetch("POST",  global.api_url + tabConst.BENUTZER);
        }
    }

    render(){
        return (
            <form>
            {/* Firma */}
            <div className="form-group">
                <label htmlFor="firmen" className="control-label">Firma</label> 
                <Select 
                placeholder = "Auswählen"
                options={ this.state.firmenSelectOptions} 
                value= { this.state.selectedFirmenOption }
                name="firmenId"
                className="basic-multi-select"
                onChange={ this.handleFirmenSelectChange }
                theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ffeebe',
                      primary: '#ffd370',
                    },
                  })}
                />
            </div>
            {/* Rolle */}
            <div className="form-group">
                <label htmlFor="rolle" className="control-label">Rolle</label> 
                <Select 
                placeholder = "Auswählen"
                options={ this.state.rolleSelectOptions} 
                value= { this.state.selectedRolleOption }
                name="rolle"
                className="basic-multi-select"
                onChange={ this.handleRolleSelectChange }
                theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ffeebe',
                      primary: '#ffd370',
                    },
                  })}
                />
            </div>
            {/* Email */}
            <div className="form-group">
                <label htmlFor="email" className="control-label">Email</label> 
                <input type="text" id="email" name="email" className="form-control" value={this.state.data.email} onChange={this.onInputchange} />
            </div>
            {/* neues Pwd */}
            <div className="form-group">
                <label htmlFor="pwd" className="control-label">Passwort</label> 
                <input type="text" id="pws" name="pwd" className="form-control" value={this.state.data.pwd} onChange={this.onInputchange} />
            </div>
        </form>
        );
    }
}
export default BenutzerForm;