import { toast } from "react-toastify";
import { saveApi } from "../../utils/DatabaseHelper";
import { ARCHIV, } from "../../utils/TableConstanten";

/**
 * 
 * @param {*} mitarbeiterId 
 */
export async function getData2Archive(mitarbeiterId) {
    // console.log("Archiviere: ", mitarbeiterId);
    const id = toast.loading("Daten werden gesammelt...", {
        position: "top-center",
    })
    await saveApi(ARCHIV+'/'+mitarbeiterId, {}) //wir senden keine Daten! diese werden Ermittelt
    .then( async (data) => {
        console.log(data)
        // console.log(data.untersuchungen.length)
        let dateiCounter = data.UntersuchungenArchiv.map(element => {
            return element.DateiArchiv.length;
        }).reduce((acc, curr) => acc + curr, 0);
        toast.update(id, { 
            render: () => <div>
                Daten Archiviert für <b>{data.nachname}, {data.vorname}</b>: <br/> 
                <ul>
                 <li>Untersuchungen: {data.UntersuchungenArchiv.length}</li>
                 <li>Dateien: {dateiCounter}</li>
                </ul>
            `
            </div>, 
            type: "success",
            autoClose: 5000, 
            isLoading: false,
            hideProgressBar: false,
            closeOnClick: true,
        });
    
    }).catch(err => {
        console.log(err)
        let inhalt = <>
            { err.message }
        </>
        toast.update(id, { 
            render: inhalt, 
            type: "error", 
            isLoading: false,
            hideProgressBar: false,
            closeOnClick: true,
        });
    
    })
}