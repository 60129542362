import React, { Component } from "react";
import PropTypes from "prop-types";
import { deleteApi, fetchApi } from "../../utils/DatabaseHelper";
import { tableColums, tableDefaultSort } from "../../config/TableViewConfig";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import LoadingSpinner from "../../utils/Loading";
import isEqual from "lodash.isequal";
import ConfirmationDialog from "../ConfirmationDialog";
import { toast } from "react-toastify";
import { getData2Archive } from "../Archive/ArchiveHelper";
import ArchiveDialog from "../ArchiveDialog";

class Table extends Component {
  defaultValues = {
    currentPage: 0,
    totalPages: 0,
    orderBy: "",
    sortOrder: "",
    loading: true,
    error: null,
    columns: [],
    data: [],
  };

  state = {
    currentPage: 0,
    totalPages: 0,
    orderBy: undefined,
    sortOrder: undefined,
    loading: true,
    error: null,
    columns: [],
    data: [],
    hasMore: false,
    isConfirmationDialogOpen: false,
    isArchivDialogOpen: false,
  };

  componentDidMount() {
    global.emitter.on("REFRESH_TABLE_DATA", this.fetchData);
    global.emitter.on("RESET", this.handleReset);
    window.addEventListener("scroll", this.handleScroll);

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.fetchData(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    global.emitter.off("REFRESH_TABLE_DATA", this.fetchData);
    global.emitter.off("RESET", this.handleReset);
  }

  handleReset = (cb) => {
    this.setState(
      { ...this.defaultValues, showPagination: false, showFilter: false },
      () => cb()
    );
  };

  fetchData = (append = false) => {
    const { apiEndpoint, filters } = this.props;
    
    let defaultSorting = tableDefaultSort[apiEndpoint] ?? {};
    let query = {
      ...filters,
      orderBy: this.state.orderBy ?? defaultSorting.orderBy ?? undefined,
      sortOrder: this.state.sortOrder ?? defaultSorting.sortOrder ?? undefined,
      currentPage: append ? this.state.currentPage : 0,
    };

    if(!this.props.embedded){
      this.props.setCsvQuery({
        apiEndpoint: apiEndpoint,
        query: query,
      })
    }
    

    let columns = tableColums[apiEndpoint] || [];
    if (this.props.embedded) {
      //apiEndpoint = report/mitarbeiter_details/:id soll werden - "/:id"
      columns = tableColums[apiEndpoint.split("/").slice(0, 2).join("/")];
    }
    console.log("fetch", apiEndpoint, query, append);
    fetchApi(apiEndpoint, "", query)
      .then((resp) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            data: append ? [...prevState.data, ...resp.data] : resp.data,
            loading: false,
            columns,
            orderBy: query.orderBy,
            sortOrder: query.sortOrder,
            currentPage: resp.currentPage + 1,
            totalPages: resp.totalPages,
            hasMore: resp.currentPage + 1 < resp.totalPages,
          }),
          // () => console.log(this.state.data)
        );
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  handleSortChange = (orderBy, sortOrder) => {
    this.setState({ orderBy, sortOrder, currentPage: 1, data: [] }, () =>
      this.fetchData()
    );
  };

  handleScroll = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    
    // Prüfen, ob der Benutzer nahe am Ende der Seite ist (z. B. innerhalb von 100px)
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.setState({ loading: true }, () => this.fetchData(true));
    }
  };

  toggleConfirmDialog(id) {
    this.setState({
      isConfirmationDialogOpen: !this.state.isConfirmationDialogOpen,
      deleteRow: id,
    });
  }

  async deleteObject(e) {
    this.toggleConfirmDialog();

    deleteApi(this.props.apiEndpoint, this.state.deleteRow)
      .then((response) => {
        // console.log("delete:", response);
        toast.success(response.message, {});
        global.emitter.emit("REFRESH_TABLE_DATA");
      })
      .catch((err) => {
        console.err("Error", err);
        toast.error(err.message, {});
      });
  }

  toggleArchiveDialog(id) {
    this.setState({
      isArchivDialogOpen: !this.state.isArchivDialogOpen,
      archivObject: id,
    });
  }
  archiveObject() {
    this.toggleArchiveDialog();
    getData2Archive(this.state.archivObject);
  }

  render() {
    const { data, columns, loading, error, hasMore } = this.state;
    const { rowClicks, onRowEdit, apiEndpoint, embedded, archive, onNachuntersuchung } = this.props;
    if (loading && data.length === 0) {
      return <LoadingSpinner show={loading} />;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }
    return (
      <>
        <table className="table table-striped ">
          <TableHeader
            key={embedded ? 11 : 1}
            columns={columns}
            order={this.state.orderBy}
            sort={this.state.sortOrder}
            handleSortChange={this.handleSortChange}
          />
          <TableBody
            key={embedded ? 12 : 2}
            embedded={embedded}
            data={data}
            rowClicks={rowClicks || false}
            columns={columns}
            onDelete={this.toggleConfirmDialog.bind(this)}
            onEdit={onRowEdit}
            onNachuntersuchung={onNachuntersuchung ?? false}
            onArchive={archive ? this.toggleArchiveDialog.bind(this) : false}
            filters={this.props.filters}
          />
        </table>
        { !embedded && (
          <span
            className="info m-3"
            style={{ textAlign: "center", display: "block", color: "grey" }}
          >
            {loading && <LoadingSpinner show={loading} />}
            {hasMore ? (
              ""
            ) : (
              <span>Alle Elemente wurden geladen.</span>
            )}
          </span>
        )}
        <ConfirmationDialog
          isOpen={this.state.isConfirmationDialogOpen}
          title={`Bestätigung erforderlich`}
          message={`Bist du sicher, dass du diesen Eintrag undwiederruflich löschen möchtest?`}
          onConfirm={this.deleteObject.bind(this)}
          onClose={this.toggleConfirmDialog.bind(this)}
        />
        <ArchiveDialog
          isOpen={this.state.isArchivDialogOpen}
          title={`Mitarbeiter Archivieren`}
          message={`Möchten Sie den Mitarbeiter Archivieren?`}
          buttons={[
            {
              label: "Archivieren",
              onClick: this.archiveObject.bind(this),
              color: "primary",
            },
            {
              label: "Abbrechen",
              onClick: this.toggleArchiveDialog.bind(this),
              color: "secondary",
            },
          ]}
          onClose={this.toggleArchiveDialog.bind(this)}
        />
      </>
    );
  }
}

Table.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  rowClicks: PropTypes.any,
  filters: PropTypes.object,
  orderBy: PropTypes.string,
  sortOrder: PropTypes.string,
  onRowEdit: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
  onNachuntersuchung: PropTypes.func,
  archive: PropTypes.bool
};

Table.defaultProps = {
  rowClicks: false,
  embedded: false,
};

export default Table;
