/**
 * 
 * Konstanten Variablen aller Tabellen, für den schnelleren Code wechsel
 * 
 */
 export const VORSORGEN = "vorsorgekartei";         //Tabelle aller vorsorgen (Vorsorgeliste...)
 export const FIRMEN = "firmen";
 export const REPORT = "report";                    //report übersichtstabelle
 export const MITARBEITER = "mitarbeiter";
 export const FIRMENKARTEI = "firmenkartei";        //Alle Vorsorgen einer Firma
 export const UNTERSUCHUNGEN = "untersuchungen";    //alle Untersuchungen
 export const ZUORDNUNG =   "zuordnung";            //Firmen bereiche, tätigkeiten oder sonst. 
 export const DATEI = "datei";                      // Liste aller dateien (.pdf hauptschlich) 
 export const ZUORDNUNG_MITARBEITER = "zuordnung_mitarbeiter"; //Relationstabelle 
 export const ZUORDNUNG_FIRMENKARTEI = ZUORDNUNG+"_"+FIRMENKARTEI; //Relationstabelle 
 export const BENUTZER = "benutzer"; //User Tabelle
 export const ABWAHL = "ausgeschlosseneUntersuchungen"; //User ausschluss Tabelle

 //Archiv
 export const ARCHIV = "archive"; 

 //WIDGETS
 export const  WIDGET_JAHRESSTATISTIK = "widget/jahresuebersicht";

 export const MITARBEITER_UEBERSICHT = "report/mitarbeiter_uebersicht"  //report grouped by Mitarbeiter
 export const MITARBEITER_DETAILS = "report/mitarbeiter_details";