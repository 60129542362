import React from 'react';
import ConfirmationDialog from '../ConfirmationDialog';
import { MdOutlineUploadFile } from "react-icons/md";

class FileUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dateien: [],
        isDragging: false,
        isConfirmationDialogOpen: false,
        datei: null,
        dateienDownloadLinks: []
      };

      this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
    }

    onDragOver = (event) => {
      event.preventDefault();
      this.setState({ isDragging: true });
    };
  
    onDragLeave = () => {
      this.setState({ isDragging: false });
    };
  
    onDrop = (event) => {
      event.preventDefault();
      this.setState({ isDragging: false });
      this.props.onFileSelected(event);
    };

    toggleConfirmDialog(e){
        let obj = null;
        if(e) obj = e.target.dataset.datei ? JSON.parse(e.target.dataset.datei) : e.target.dataset.datei;
        this.setState({isConfirmationDialogOpen: !this.state.isConfirmationDialogOpen, datei: obj});
    }
  
    removeDatei = (name) => {
        const dateien = this.state.dateien.filter(datei => datei.name !== name);
        this.setState({ dateien });
    };
  
    render() {
      return (
        <>
          {/* Datei hochladen */}
          <label htmlFor="file" className="control-label">
            Bescheinigung hochladen <em className="small">(maximal 12MB pro Datei)</em>
          </label>
          <div className="file-upload-component" onClick={() => document.getElementById('file').click()}>
              <div
                  className={`form-group ${this.state.isDragging ? "dragging" : ""}`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
              >
                  <input id="file" multiple type="file" name="file" className="form-control file-input" onChange={this.props.onFileSelected} />
                  <div className="drag-drop-overlay">
                      <p><span><MdOutlineUploadFile /></span> Datei auswählen</p>
                  </div>
              </div>
          </div>

          {/* Anzeige der hochgeladenen Dateien */}
          <div className="form-group">
              <div id="dateien" name="dateien" className="row">
                  {this.state.dateien.map((datei, idx) => (
                      <div key={idx} className="col-auto text-center pt-3 mx-3 datei">
                          <span>
                              <i className="far fa-file-alt" />
                              <p className="datei-name">{datei.name}</p>
                          </span>
                          {this.state.isEdit ? (
                              <i
                                  className="fas fa-times"
                                  data-datei={JSON.stringify(datei)}
                                  onClick={this.toggleConfirmDialog}
                              ></i>
                          ) : (
                              <i
                                  className="fas fa-times"
                                  data-datei={JSON.stringify(datei)}
                                  onClick={() => this.removeDatei(datei.name)} // Hier wird die Datei entfernt
                              ></i>
                          )}
                      </div>
                  ))}
                  {this.state.dateienDownloadLinks}
              </div>
              {this.props.isEdit ? 
                (<ConfirmationDialog
                        isOpen={this.state.isConfirmationDialogOpen}
                        title="Datei löschen"
                        message={`Möchten Sie die Datei: <b>${this.state.datei ? this.state.datei.name : 'undefined'}</b> wirklich löschen?`}
                        onConfirm={() => this.props.onConfirmDelete(this.state.datei)}
                        onClose={this.toggleConfirmDialog}
                    />) 
                : (null)}
          </div>
        </>
      );
    }
}
  
export default FileUpload;
