import React from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomCheckbox = ({ checked, onChange, label, name }) => {
  return (
    <Label className="custom-checkbox">
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
        className="custom-checkbox-input"
      />
      <span className="custom-checkbox-box"></span>
      {label}
    </Label>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomCheckbox;