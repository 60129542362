
export function url_creation(resName, resourceIdOrQuery = '', maybeQuery = ''){
    let resourceId = '';
    let query = '';
    let headers = {
        method: "GET",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }
    //Query könnte auch ein Objekt sein, daher erst in String wandeln
    if(typeof resourceIdOrQuery === 'object'){
        resourceIdOrQuery = queryObjectToString(resourceIdOrQuery);
    }

    // Determine which parameter is which
    if (typeof resourceIdOrQuery === 'string' && resourceIdOrQuery.startsWith('?')) {
        query = resourceIdOrQuery;
    } else {
        resourceId = resourceIdOrQuery !== '' ? '/'+resourceIdOrQuery : '';
        if (typeof maybeQuery === 'object') {
            query = queryObjectToString(maybeQuery);
        } else {
            query = maybeQuery;
        }
    }

    return {url: global.api_url + resName + resourceId + query, resourceId, query, headers}
}

/**
 * 
 * @param {Resouce Name} resName 
 * @param {id for single, can be empty} resourceId 
 * @param {query Params} query 
 */
export async function fetchApi(resName, resourceIdOrQuery = '', maybeQuery = '') {
    const {url, resourceId, query, headers} = url_creation(resName, resourceIdOrQuery, maybeQuery);

    return new Promise((resolve, reject) => {
        // console.log(global.api_url + resName + resourceId + query, headers)
        fetch(url, headers)
        .then(res => {
            if (res.ok) {
                // Falls kein CSV-Download, dann JSON zurückgeben
                return res.json();
            }
            throw new Error('Fehler beim Abrufen der Daten: ' + res.status);
        })
        .then(response => {
            if (!query.includes('format=csv')) {
                if (response.status === 404 || response.status === 500) {
                    return reject(response);
                }
                return resolve(response); // JSON-Antwort
            }
        })
        .catch(err => {
            return reject(err);
        })
    })
}

/**
 * 
 * @param {*} resName 
 * @param {*} resourceId 
 * @param {*} data 
 * @returns Promise(resolve(response.json), reject(err))
 */
export async function putApi(resName, resourceId = '', data){
    let headers = {
        method: "PUT",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);

    // console.log('ketest', headers.body)

    if(resourceId !== '') resourceId = '/'+ resourceId;

    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName + resourceId, headers)
            .then(res => res.json())
            .then(response => {
                return resolve(response)
            })
            .catch(err => {
                return reject(err);
            })
    })
}

export async function saveApi(resName, data){
    let headers = {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token 
        } 
    }
    headers.body = JSON.stringify(data);
    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName, headers)
            .then(res => res.json())
            .then(response => {
                return resolve(response)
            })
            .catch(err => {
                return reject(err);
            })
    })
}


export async function deleteApi(resName, resourceId){
    let headers = {
        method: "DELETE",
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': global.auth.authToken.token,
            'firmenid': global.filterFirmenId
        } 
    }

    return new Promise((resolve, reject) => {
        fetch(global.api_url + resName +"/" + resourceId, headers)
            .then(res => res.json())
            .then(response => {
                // console.log('delete ok')
                return resolve(response)
            })
            .catch(err => {
                console.log('err', err)
                return reject(err);
            })
    }) 
}



// Interne Helper Functions
function queryObjectToString(query) {
    const params = new URLSearchParams();
  
    for (const [key, value] of Object.entries(query)) {
      if (value !== undefined) {
        if (['sortOrder', 'orderBy', 'limit', 'currentPage', 'format'].includes(key)) {
          params.append(key, value);
        } else {
          params.append('filterby_' + key, value);
        }
      }
    }
  
    return params.toString() !== '' ? '?' + params.toString() : '';
  }
