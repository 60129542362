import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsChatRight, BsFillTriangleFill } from "react-icons/bs";


const TableHeader = ({ columns, order, sort, handleSortChange }) => {
  const [orderBy, setOrderBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    if (order && sort) {
      setOrderBy(order);
      setSortOrder(sort);
    }
  }, [order, sort]);

  const handleOrderBy = (dbName, order) => {
    setOrderBy(dbName);
    setSortOrder(order);
    handleSortChange(dbName, order);
  };

  const orderby = (column) => {
    const { dbName, label } = column;
    const arrowStyle = {
      position: "absolute",
      right: '1rem',
      fontSize: ".5em"
    }
    return (
      <div style={{position: 'relative'}}>
        <span>{label}</span>
        <BsFillTriangleFill style={{
            ...arrowStyle, 
            top: "calc(50% - 8px)",
            cursor: "pointer",
            opacity: orderBy === dbName && sortOrder === "ASC" ? 1 : 0.5,
          }}
          onClick={() => handleOrderBy(dbName, "ASC")}
        />

        <BsFillTriangleFill 
          style={{
            ...arrowStyle, 
            transform: "rotate(180deg)", 
            bottom: "calc(50% - 8px)",
            cursor: "pointer",
            opacity: orderBy === dbName && sortOrder === "DESC" ? 1 : 0.5,
          }}
          onClick={() => handleOrderBy(dbName, "DESC")}
        />
      </div>
    );
  };

  return (
    <thead>
      <tr >
        {columns.map((column) => (
          <th key={column.dbName}>{orderby(column)}</th>
        ))}
        <th style={{ textAlign: "right" }}></th>
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dbName: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string,
    })
  ).isRequired,
  order: PropTypes.string,
  sort: PropTypes.oneOf(["ASC", "DESC"]),
  handleSortChange: PropTypes.func.isRequired
};

export default TableHeader;
