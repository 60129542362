export function CustomLabel({viewBox, value1, value2}){
    const {cx, cy} = viewBox;
    return (<>
        <text x={cx} y={cy -5} fill="black" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="36">{value1}</tspan>
        </text>
        <text x={cx} y={cy +20} fill="#B3ACA9" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="10">{value2}</tspan>
        </text>
    </>)
}

export function PieCharLabel(value1, value2){
    return (<>
        <text offset="5" width="30" x="146.5" y="125" className="recharts-text recharts-label" text-anchor="middle" fill="black"><tspan x="146.5" dy="0.355em">5</tspan></text>
    </>)
} 