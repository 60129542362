import React, { Component } from "react";
import { schemaToForm } from "../../config/MenuConfig";
import PropTypes from "prop-types";
import {
  IndividualForm,
  IndividualForms,
} from "./individualForms/IndividualForm";
import Form from "../Form";
import { toast } from "react-toastify";

class FormBox extends Component {
  state = {
    errors: {},
  };

  constructor(props){
    super(props)

    this.formRef = React.createRef();
  }


  validate(props) {
    console.log("val", props);

    //schema ermitteln und danach Title setzten
    if (props.hash in schemaToForm)
      this.setState(
        { schemaResourceName: schemaToForm[props.hash].schemaResourceName },
        () => {
          //setzen der defaults
          props.changeTitle(schemaToForm[props.hash].label);
        }
      );
  }

  componentDidMount() {
    this.validate(this.props);
  }

  componentWillUnmount() {
    this.props.changeTitle(null);
  }

  handleSaveClick = async () => {
    if (this.formRef && this.formRef.current) {
      try {
        this.canClose(await this.formRef.current.saveOrUpdate())
      } catch (err) {
        console.log(err.details);
        this.setState({errors: err.details})
      }
    } else {
      this.onClose();
    }
  }

  canClose(ret = undefined){
    // console.log("can?", ret)
    // console.log("canClose?", !ret || (ret && !ret.error), ret)
    if(!ret || (ret && !ret.error) ){
      this.onClose()
      toast.success("Erfolgreich gespeichert.")
    }else{
      toast.error(ret.error, {});
    }
  }

  //wir linken einfach wieder auf die Resource
  onClose() {
    const hash = this.props.hash.replace(/^new_/, '');
    window.location.hash = (`#${hash}`);
    // window.location.reload()
  }

  render() {
    let form = <p>Nope</p>;
    const FormComponent = IndividualForms.includes(
      this.state.schemaResourceName
    )
      ? IndividualForm
      : Form;
    if (this.state.schemaResourceName) {
      form = (
        <FormComponent
          resourceName={this.state.schemaResourceName}
          schemas={this.props.schemas}
          schema={this.props.schemas[this.state.schemaResourceName]}
          ref={this.formRef}
          formData={{}}
        />
      );
    }

    let errorNotice = <div className='errorNotices'>{Object.keys(this.state.errors).map((key, index) => (
        <p key={index} className="errorNotice"><span>{`${this.state.errors[key].label}: `}</span>{`${this.state.errors[key].status}`}</p>
      ))}</div>

    return (
      <section className="col p-0 formBox">
        <div className="align-items-center">
          {/* <div className="row">
                <p>befor card</p>
            </div> */}
        </div>
        <div className="card">
          <div
            className="card-body"
            style={{ maxWidth: "100%", overflowX: "auto"}}
          >
            {errorNotice}
            {form}
          </div>
          <div className="card-footer d-flex" style={{ backgroundColor: "unset", justifyContent: "right" }}>
            <button style={{marginRight: '1.143rem'}} type="button" className="btn btn-outline" onClick={this.onClose.bind(this)}>Abbrechen</button>
            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)}>Speichern</button>
          </div>
        </div>
      </section>
    );
  }
}

FormBox.propTypes = {
  changeTitle: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  schemas: PropTypes.object.isRequired,
};

export default FormBox;
